import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TaskList from './TaskList';
import UploadModal from './UploadModal';
import LoanDocumentInfoModal from '../Loans/LoanDocumentInfoModal';
import FilePreviewUI from '../Loans/FilePreviewUI';

const ProfilePage = ({
  userInfo, loanConfig, getUserInfo,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [taskSelected, setTaskSelected] = useState(-1);

  const [documentInfoModalOpen, setDocumentInfoModalOpen] = useState(false);
  const [selectedFileID, setSelectedFileID] = useState(null);
  const [filePreviewUIOpen, setFilePreviewUIOpen] = useState(false);

  /**
   * Opens the upload modal
   * @param {number} index
   */
  const selectTask = (task, index) => {
    if (task.Completed) {
      return;
    }
    setTaskSelected(index);
    setModalOpen(true);
  };

  /**
   * Close the Upload Modal and reset the state
   */
  const closeModal = () => {
    setModalOpen(false);
    setTaskSelected(-1);
  };

  const resetState = () => {
    setTaskSelected(-1);
    setSelectedFileID(null);
  };

  const viewTaskDocument = (fileID) => {
    setDocumentInfoModalOpen(true);
    setSelectedFileID(fileID);
  };

  const previewTaskFile = (fileID) => {
    setFilePreviewUIOpen(true);
    setSelectedFileID(fileID);
  }

  return (
    <>
      <div className="flex-row margin-left-auto margin-right-auto margin-top-60 text-align-center content-max-width">
        <div className="flex margin-left-20 margin-right-20 padding-left-20 padding-right-20 text-align-left">
          <h2 className="margin-bottom-30">Your Information</h2>
          {userInfo && (
            <>
              <h3 className="margin-bottom-10">Name</h3>
              <p className="margin-bottom-20">{userInfo.Name}</p>
              <h3 className="margin-bottom-10">Email</h3>
              <p className="margin-bottom-20">{userInfo.Email}</p>
              <h3 className="margin-bottom-10">Phone Number</h3>
              <p className="margin-bottom-20">
                {userInfo.Phone.length === 10 ? `(${userInfo.Phone.slice(0, 3)}) ${userInfo.Phone.slice(3, 6)}-${userInfo.Phone.slice(6, 10)}` : userInfo.Phone}
              </p>
              <h3 className="margin-bottom-10">Address</h3>
              <p className="margin-bottom-10">{userInfo.Address.Address}</p>
              <p className="margin-bottom-50">
                {userInfo.Address.City}
                {userInfo.Address.City.length === 0 ? "" : ", "}
                {userInfo.Address.State}
                {userInfo.Address.State.length === 0 ? "" : ", "}
                {userInfo.Address.Zip}
              </p>
              {/* Placeholder info */}
              <h3 className="margin-bottom-10">Advisor Name</h3>
              <p className="margin-bottom-20">Forrest Ray</p>
              <h3 className="margin-bottom-10">Branch Address</h3>
              <p className="margin-bottom-10">511-5762 At Rd.</p>
              <p className="margin-bottom-20">Chelsea MI 67708</p>
              {/* Placeholder info */}
              <h3 className="margin-bottom-10">Number of Loans</h3>
              <p className="margin-bottom-20">{userInfo.Loans.length}</p>
            </>
          )}
        </div>
        <div className="flex">
          <TaskList
            userInfo={userInfo}
            selectTask={selectTask}
            viewTaskDocument={viewTaskDocument}
            previewTaskFile={previewTaskFile}
          />
        </div>
      </div>
      <UploadModal
        taskSelected={taskSelected}
        modalOpen={modalOpen}
        closeModal={closeModal}
        userInfo={userInfo}
        loanConfig={loanConfig}
        getUserInfo={getUserInfo}
      />
      <LoanDocumentInfoModal
        modalOpen={documentInfoModalOpen}
        closeModal={setDocumentInfoModalOpen}
        fileID={selectedFileID}
        resetState={resetState}
      />
      {filePreviewUIOpen && <FilePreviewUI 
        setModalOpen={setFilePreviewUIOpen}
        fileID={selectedFileID}
      />}
    </>
  );
};

ProfilePage.defaultProps = {
  userInfo: null,
  loanConfig: null,
};

ProfilePage.propTypes = {
  userInfo: PropTypes.shape({
    Name: PropTypes.string,
    Email: PropTypes.string,
    Loans: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  loanConfig: PropTypes.shape({}),
  getUserInfo: PropTypes.func.isRequired,
};

export default ProfilePage;
