import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/logo-whiteorange.png';
import Footer from '../../components/Footer';

/**
 * Main landing page for the application.
 */
const IndexPage = ({ history }) => {
  const goToLoginPage = () => {
    history.push('/login');
  };

  return (
    <div className="flex-column page">
      {/* Header */}
      <div className="flex-row align-items-center padding-top-70 padding-bottom-30 background-purple">
        <div className="margin-left-auto margin-right-auto" style={{ maxWidth: 1080 }}>
          <img src={logo} alt="Reva Solutions Logo" width={400} />
        </div>
      </div>
      {/* Body */}
      <div className="flex-row margin-top-70 margin-left-auto margin-right-auto" style={{ maxWidth: 1080 }}>
        <div className="flex-column padding-right-70">
          <h1>Loan Documents Client Portal</h1>
          <hr />
          <h3 className="margin-top-20">Managing Loans Easier.</h3>
        </div>
        <div>
          <button onClick={goToLoginPage} type="button" className="border-radius-10 padding-top-30 padding-bottom-30 padding-left-50 padding-right-50 background-orange cursor-pointer color-white orange">
            <h2>LOGIN</h2>
          </button>
        </div>
      </div>
      <div className="flex-row margin-top-70 margin-left-auto margin-right-auto" style={{ maxWidth: 1080 }}>
        <div className="padding-30 margin-right-10 border-grey background-white">
          <h2 className="margin-bottom-20">
            Who We Are
          </h2>
          <p>We provide services and loans.</p>
        </div>
        <div className="padding-30 margin-right-10 border-grey background-white">
          <h2 className="margin-bottom-20">
            Advisors on Demand
          </h2>
          <p>We are here to help.</p>
        </div>
        <div className="padding-30 border-grey background-white">
          <h2 className="margin-bottom-20">
            Latest News
          </h2>
          <p>Reva teams up with Box.</p>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

IndexPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default IndexPage;
