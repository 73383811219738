import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'rc-progress';
import {
  useRouteMatch,
  Link,
} from 'react-router-dom';

const DashboardPage = ({ userInfo }) => {
  const match = useRouteMatch();

  const loans = useMemo(() => {
    if (userInfo && userInfo.Loans) {
      return userInfo.Loans;
    }
    return null;
  }, [userInfo]);

  const generateTaskProgress = () => {
    if (userInfo && userInfo.Tasks) {
      const numOfTasksCompleted = userInfo.Tasks.filter((task) => task.Completed).length;
      const percentage = (numOfTasksCompleted / userInfo.Tasks.length).toFixed(2) * 100;
      return (
        <>
          <Circle percent={percentage} strokeWidth="5" strokeColor="#e67e22" style={{ width: 100 }} />
          <p>{`Completed: ${percentage}%`}</p>
        </>
      );
    }
    return null;
  };

  return (
    <div className="flex-row margin-left-auto margin-right-auto margin-top-60 text-align-center content-max-width margin-bottom-30">

      {/* Left Body */}
      <div className="flex margin-left-20 margin-right-20 background-white padding-30 border-radius-10 border-grey">
        <h2 className="margin-bottom-20">Dashboard</h2>

        <div className="flex-row justify-content-center align-items-center " style={{ flexWrap: 'wrap' }}>
          <div className="text-align-center flex margin-bottom-20 margin-left-auto margin-right-auto" style={{ minWidth: 200, maxWidth: 200 }}>
            <h3>Profile Tasks</h3>
            <h4>&nbsp;</h4>
            {generateTaskProgress()}
          </div>

          {loans && loans.map((loan) => {
            const { LoanTasks, LoanNumber, LoanType } = loan;
            const numOfTasksCompleted = LoanTasks.filter((task) => task.Completed).length;
            const percentage = (numOfTasksCompleted / LoanTasks.length).toFixed(2) * 100;
            return (
              <div key={LoanNumber} className="text-align-center flex margin-bottom-20 margin-left-auto margin-right-auto" style={{ minWidth: 200, maxWidth: 200 }}>
                <h3>{`${LoanType}`}</h3>
                <h4>{`#: ${LoanNumber}`}</h4>
                <Circle percent={percentage} strokeWidth="5" strokeColor="#e67e22" style={{ width: 100 }} />
                <p>{`Completed: ${percentage}%`}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Right Body */}
      <div className="flex flex-column margin-right-20 margin-left-20">
        <div className="border-grey border-radius-5 padding-20 grow">
          <Link to={`${match.url}/profile`}>
            <h3 className="margin-bottom-10 cursor-pointer orange-hover">Complete your Profile</h3>
          </Link>
          <p>Upload documents to speed up the approval process.</p>
        </div>
        <div className="border-grey border-radius-5 padding-20 margin-top-30 grow">
          <Link to={`${match.url}/loans`}>
            <h3 className="margin-bottom-10 cursor-pointer orange-hover">View Loans</h3>
          </Link>
          <p>Check the status of your loans.</p>
        </div>
      </div>
    </div>
  );
};

DashboardPage.defaultProps = {
  userInfo: null,
};

DashboardPage.propTypes = {
  userInfo: PropTypes.shape({
    Loans: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    Tasks: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
};

export default DashboardPage;
