export const applyCookie = (key, value, time = 0) => {
  let expiryTime = '';
  if (time) {
    expiryTime = new Date(time).toUTCString();
  } else {
    expiryTime = new Date(Date.now() + 1000 * 60 * 60).toUTCString();
  }
  document.cookie = `${key}=${value};expires=${expiryTime};path=/;SameSite=None;Secure`;
};

export const getCookie = (key) => {
  const name = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const removeCookie = (key) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;SameSite=None;Secure`;
};
