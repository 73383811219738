import React, { useEffect, useState } from "react";
import logoSquare from "../../../assets/logo-square.png";
import { getCookie } from "../../../utils/cookies";

const FilePreviewUI = ({ setModalOpen, fileID }) => {
  const [jsLoaded, setJsLoaded] = useState(false);
  const [cssLoaded, setCssLoaded] = useState(false);

  const jsLoad = () => {
    setJsLoaded(true);
  };
  const cssLoad = () => {
    setCssLoaded(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn01.boxcdn.net/platform/preview/2.69.0/en-US/preview.js";
    script.async = true;
    script.onload = jsLoad;
    document.head.appendChild(script);

    const link = document.createElement("link");
    link.href =
      "https://cdn01.boxcdn.net/platform/preview/2.69.0/en-US/preview.css";
    link.rel = "stylesheet";
    link.onload = cssLoad;
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    if (jsLoaded && cssLoaded) {
        
      const { Box } = window;
      const preview = new Box.Preview();
      
      const fileid = fileID;
      const { accessToken } = JSON.parse(getCookie('boxToken'));

      preview.show(fileid, accessToken, {
        container: ".container",
        logoUrl: logoSquare,
        showDownload: true,
      });
    }
  }, [jsLoaded, cssLoaded, fileID]);

  // Listener to close modal when clicked on the background
  useEffect(() => {
    const listener = (e) => {
      if (e.target.matches(".filePreviewUI")) {
        setModalOpen(false);
      }
    };
    window.addEventListener("mousedown", listener);
    return function cleanup() {
      window.removeEventListener("mousedown", listener);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="pagemodal filePreviewUI flex-column margin-top-50">
      <div className="pagemodal-content-wide pagemodal-height">
        <div className="container full-height" />
      </div>
      <div>
        <button
          className="cursor-pointer border-radius-5 margin-right-20 margin-top-10 padding-left-70 padding-right-70 padding-top-10 padding-bottom-10"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <h2>Done</h2>
        </button>
      </div>
    </div>
  );
};

export default FilePreviewUI;
