import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import logoSquare from '../../../assets/logo-square.png';
import { getCookie } from '../../../utils/cookies';
import UploadFileModal from './UploadFileModal';
import LoanDocumentInfoModal from './LoanDocumentInfoModal';

const LoansPage = ({ userInfo, getUserInfo }) => {
  const [jsLoaded, setJsLoaded] = useState(false);
  const [cssLoaded, setCssLoaded] = useState(false);

  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [taskIndex, setSelectedTaskIndex] = useState(-1);

  const [loanDocumentInfoModalOpen, setLoanDocumentInfoModalOpen] = useState(false);
  const [selectedFileID, setSelectedFileID] = useState(null);

  const loans = useMemo(() => {
    if (userInfo && userInfo.Loans) {
      return userInfo.Loans;
    }
    return null;
  }, [userInfo]);

  const jsLoad = () => {
    setJsLoaded(true);
  };
  const cssLoad = () => {
    setCssLoaded(true);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/explorer.js';
    script.async = true;
    script.onload = jsLoad;
    document.head.appendChild(script);

    const link = document.createElement('link');
    link.href = 'https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/explorer.css';
    link.rel = 'stylesheet';
    link.onload = cssLoad;
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    if (jsLoaded && cssLoaded) {
      const { Box } = window;
      const { ContentExplorer } = Box;
      const contentExplorer = new ContentExplorer();

      const folderId = '0';
      const { accessToken } = JSON.parse(getCookie('boxToken'));

      contentExplorer.show(folderId, accessToken, {
        container: '.container',
        logoUrl: logoSquare,
        canUpload: false,
        canCreateNewFolder: false,
        canRename: false,
        canShare: false,
        canDelete: false,
      });
    }
  }, [jsLoaded, cssLoaded]);

  /**
   * Open modal to upload file to loan
   * @param {*} loan Loan Object
   * @param {*} index index of task
   */
  const openModal = (loan, index) => {
    setUploadFileModalOpen(true);
    setSelectedLoan(loan);
    setSelectedTaskIndex(index);
    console.log(loan);
  };

  /**
   * Open modal to view document info
   * @param {*} fileID string
   */
  const openLoanDocumentModal = (fileID) => {
    setSelectedFileID(fileID);
    setLoanDocumentInfoModalOpen(true);
  };

  /**
   * Reset the page state.
   */
  const resetState = () => {
    setSelectedLoan(null);
    setSelectedTaskIndex(-1);
    setUploadFileModalOpen(false);
    setSelectedFileID(null);
  };

  return (
    <>
      <div className="flex-row margin-left-auto margin-right-auto margin-top-60 margin-bottom-20 text-align-center content-max-width">
        <div className="container flex margin-left-20 margin-right-20 border-grey" />
        <div className="flex margin-left-20 margin-right-20">
          <h2 className="margin-bottom-20">Loan Statuses</h2>
          <>
            {loans && (
              loans.map((loan) => {
                const { LoanNumber, LoanTasks, LoanType } = loan;
                return (
                  <div key={LoanNumber} className="margin-bottom-40 text-align-left padding-left-50 border-grey padding-20 border-radius-10">
                    <h3 className="margin-bottom-10">{`Loan: ${LoanType}`}</h3>
                    <h4 className="margin-bottom-10">{`Loan Number: ${LoanNumber}`}</h4>
                    {LoanTasks && (
                      LoanTasks.map((task, index) => {
                        const {
                          Name, UserAction, Completed, BoxFileID, ToExtract,
                        } = task;
                        return (
                          <div key={`${LoanNumber} - ${Name}`} className="margin-bottom-10 margin-left-20">
                            <div className="flex-row">
                              <input disabled type="checkbox" checked={Completed} />
                              {Completed && BoxFileID && ToExtract ? (
                                // Button included to open LoanDocumentInfo Modal
                                <h3 className="margin-left-5">
                                  {`${Name}`}
                                  <button type="button" className="background-none h3 color-blue cursor-pointer margin-left-5" onClick={() => openLoanDocumentModal(BoxFileID)}>
                                    (View)
                                  </button>
                                </h3>
                              ) : (
                                // No Button
                                <h4 className="margin-left-5">{Name}</h4>
                              )}
                            </div>
                            {
                              UserAction && !Completed && (
                                <button
                                  type="button"
                                  className="margin-left-20 background-none cursor-pointer"
                                  onClick={() => openModal(loan, index)}
                                >
                                  <p className="">Click to upload relevant document.</p>
                                </button>
                              )
                            }
                          </div>
                        );
                      })
                    )}
                  </div>
                );
              })
            )}
          </>
        </div>
      </div>
      <UploadFileModal
        modalOpen={uploadFileModalOpen}
        closeModal={setUploadFileModalOpen}
        selectedLoan={selectedLoan}
        userInfo={userInfo}
        taskIndex={taskIndex}
        getUserInfo={getUserInfo}
        resetState={resetState}
      />
      <LoanDocumentInfoModal
        modalOpen={loanDocumentInfoModalOpen}
        closeModal={setLoanDocumentInfoModalOpen}
        fileID={selectedFileID}
        resetState={resetState}
      />
    </>
  );
};

LoansPage.defaultProps = {
  userInfo: null,
};

LoansPage.propTypes = {
  userInfo: PropTypes.shape({
    Loans: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
  getUserInfo: PropTypes.func.isRequired,
  // loanConfig: PropTypes.shape({}).isRequired,
};

export default LoansPage;
