/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { useDropzone } from 'react-dropzone';
import { FileIcon, defaultStyles } from 'react-file-icon';

import { UPLOAD_FILE_TO_LOAN } from '../../../utils/api';

/**
 * Upload Modal for Loan
 * Makes use of React dropzone
 */
const UploadFileModal = ({
  modalOpen, closeModal, selectedLoan, userInfo, taskIndex, getUserInfo, resetState,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  // Add file to state when file is added
  const onDrop = useCallback((acceptedFiles) => {
    const firstFile = acceptedFiles[0];
    setSelectedFiles(firstFile);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Close Modal and reset states for modal.
  const closeModalAndReset = async () => {
    closeModal(false);
    setSelectedFiles(null);
    setUploading(false);
    setUploadComplete(false);
    resetState();
    if (uploadComplete && !uploading) {
      await getUserInfo();
    }
  };

  const uploadFileToLoan = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('step', taskIndex);
    formData.append('file', selectedFiles);
    formData.append('cognitoID', userInfo.CognitoID);
    formData.append('loanNumber', selectedLoan.LoanNumber);
    try {
      const response = await UPLOAD_FILE_TO_LOAN(formData);
      setUploading(false);
      setUploadComplete(true);
      console.log(response);
    } catch (err) {
      setUploadComplete(true);
      alert(err);
    }
  };

  return (
    <div className="pagemodal" style={{ display: `${modalOpen ? 'flex' : 'none'}` }}>
      <div className="pagemodal-content flex-column">
        {/* Title */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>{selectedLoan && selectedLoan.LoanTasks ? selectedLoan.LoanTasks[taskIndex].Name : 'Task'}</h1>
          <button className="cursor-pointer flex-row align-items-center orange" onClick={closeModalAndReset} type="button" style={{ borderRadius: 100 }}>
            <CloseIcon />
          </button>
        </div>
        {/* Upload files UI */}
        {!uploading && !uploadComplete && (
          <>
            <div {...getRootProps()} className="border-grey padding-50 text-align-center" style={{ border: '1px dotted black', cursor: 'pointer' }}>
              <input {...getInputProps()} />
              {
                isDragActive && (
                  <p>Drop the files here ...</p>
                )
              }
              {
                !selectedFiles && !isDragActive && (
                  <p>Drag and drop some files here, or click to select files</p>
                )
              }
              {
                selectedFiles && !isDragActive && (
                  <>
                    <div className="text-align-left ">
                      <div className="flex-row align-items-center">
                        <div className="margin-right-20" style={{ maxWidth: 75, maxHeight: 75 }}>
                          <FileIcon extension={selectedFiles.path.split('.')[1]} {...defaultStyles[selectedFiles.path.split('.')[1]]} />
                        </div>
                        <div>
                          <div className="flex-row">
                            <p style={{ width: 125 }}>{'File Name: '}</p>
                            <p>{`${selectedFiles.name}`}</p>
                          </div>
                          <div className="flex-row">
                            <p style={{ width: 125 }}>{'File Type: '}</p>
                            <p>{`${selectedFiles.type}`}</p>
                          </div>
                          <div className="flex-row">
                            <p style={{ width: 125 }}>{'Last Modified: '}</p>
                            <p>{`${new Date(selectedFiles.lastModified).toDateString()}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="margin-top-50">
                      <p>
                        Not the correct file?
                        Click to select another file or drag another file here.
                      </p>
                    </div>
                  </>
                )
              }
            </div>
            {selectedFiles && (
              <div>
                <button
                  onClick={uploadFileToLoan}
                  type="button"
                  className="padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 cursor-pointer margin-top-20 background-blue color-white"
                >
                  Upload
                </button>
              </div>
            )}
          </>
        )}

        {/* Uploading UI */}
        {uploading && !uploadComplete && (
          <div className="text-align-center">
            <CircularProgress size={60} />
          </div>
        )}

        {/* Upload Error UI */}
        {uploading && uploadComplete && (
          <div>
            <h3>Error in Uploading. Please try again.</h3>
            <button
              onClick={closeModalAndReset}
              type="button"
              className="padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 cursor-pointer margin-top-20 background-blue color-white"
            >
              Close Modal
            </button>
          </div>
        )}
        {/* Upload Complete UI */}
        {!uploading && uploadComplete && (
          <div>
            <h3>File uploaded successfully!</h3>
            <button
              onClick={closeModalAndReset}
              type="button"
              className="padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 cursor-pointer margin-top-20 background-blue color-white"
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

UploadFileModal.defaultProps = {
  userInfo: null,
  selectedLoan: null,
};

UploadFileModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedLoan: PropTypes.shape({
    LoanTasks: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string,
      }),
    ),
    LoanNumber: PropTypes.string,
  }),
  userInfo: PropTypes.shape({
    CognitoID: PropTypes.string,
  }),
  taskIndex: PropTypes.number.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};

export default UploadFileModal;
