import React, { useEffect, useState } from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import { GET_USER_INFO, GET_LOAN_CONFIG } from '../../utils/api';

import DashboardPage from '../MainPages/Dashboard';
import LoansPage from '../MainPages/Loans';
import ProfilePage from '../MainPages/Profile';
import EditProfilePage from '../MainPages/EditProfile';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const MainPage = () => {
  const match = useRouteMatch();

  const [loanConfig, setLoanConfig] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [goToLogout, setGoToLogout] = useState(false);

  /**
   * Logs out of the Session by redirecting to Logout Page
   */
  const logout = async () => {
    setGoToLogout(true);
  };

  /**
   * Retrieves user info and sets it as component state.
   */
  async function getUserInfo() {
    try {
      const { Item } = await GET_USER_INFO();

      setUserInfo(Item);
    } catch (err) {
      console.log(err);
      // window.location = 'https://loan-docs-portal.revalabs.net';
    }
  }

  async function updateLoanConfig() {
    try {
      const { Item } = await GET_LOAN_CONFIG();
      setLoanConfig(Item);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getUserInfo();
    updateLoanConfig();
  }, []);

  if (goToLogout) {
    return <Redirect to="/logout" />;
  }

  return (
    <>
      <div className="flex-column" style={{ minHeight: '100vh' }}>
        {/* Header */}
        <Header
          userInfo={userInfo}
          logout={logout}
        />

        {/* Body */}
        <>
          <Route
            exact
            path={match.path}
            component={() => (
              <DashboardPage
                userInfo={userInfo}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/loans`}
            component={() => (
              <LoansPage
                loanConfig={loanConfig}
                userInfo={userInfo}
                getUserInfo={getUserInfo}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/profile`}
            component={() => (
              <ProfilePage
                loanConfig={loanConfig}
                userInfo={userInfo}
                getUserInfo={getUserInfo}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/editProfile`}
            component={() => (
              <EditProfilePage
                loanConfig={loanConfig}
                userInfo={userInfo}
                getUserInfo={getUserInfo}
              />
            )}
          />
        </>
      </div>
      {/* Footer */}
      <Footer />
    </>

  );
};

export default MainPage;
