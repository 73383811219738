import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import { GET_DOCUMENT_AI_RESULT } from '../../../utils/api';

function extractText(textAnchor, text) {
  // First shard in document doesn't have startIndex property
  const startIndex = textAnchor.textSegments[0].startIndex || 0;
  const { endIndex } = textAnchor.textSegments[0];

  return text.substring(startIndex, endIndex);
}

const LoanDocumentInfoModal = ({
  modalOpen, closeModal, fileID, resetState,
}) => {
  const [documentResult, setDocumentResult] = useState(null);
  const [getResultFailed, setGetResultFailed] = useState(false);

  /**
   * Close modal and reset states.
   */
  const closeModalAndReset = () => {
    closeModal(false);
    setDocumentResult(null);
    setGetResultFailed(false);
    resetState();
  };

  /**
   * Render results of Document AI
   */
  const renderResults = () => {
    if (getResultFailed) {
      return (
        <div>
          <h2 className="margin-bottom-20">The file is still being processed...</h2>
          <p>Please try again later.</p>
        </div>
      );
    }
    if (!documentResult) {
      return (
        <div className="text-align-center">
          <CircularProgress size={60} />
        </div>
      );
    }

    const { text } = documentResult;
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell align="right">Value</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Confidence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentResult.entities.map((entity, index) => {
              const { textAnchor, confidence, mentionText, type } = entity;
              return (
                <TableRow key={index}>
                  <TableCell>{extractText(textAnchor, text)}</TableCell>
                  <TableCell align="right">{mentionText}</TableCell>
                  <TableCell align="right">{type}</TableCell>
                  <TableCell align="right">{Math.round(confidence * 100)}%</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getDocumentAIResult = async (boxFileID) => {
    try {
      if (!boxFileID) return;
      const response = await GET_DOCUMENT_AI_RESULT(boxFileID);
      console.log(response);
      setDocumentResult(response);
    } catch (err) {
      console.log(err);
      setGetResultFailed(true);
    }
  };

  useEffect(() => {
    getDocumentAIResult(fileID);
  }, [fileID]);

  // Listener to close modal when clicked on the background
  useEffect(() => {
    const listener = (e) => {
      if (e.target.matches(".resultModal")) {
        closeModalAndReset();
      }
    };
    window.addEventListener("mousedown", listener);
    return function cleanup() {
      window.removeEventListener("mousedown", listener);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="resultModal pagemodal" style={{ display: `${modalOpen ? 'flex' : 'none'}` }}>
      <div className="pagemodal-content-wide flex-column" style={{ maxHeight: '80%', overflowY: 'auto' }}>
        {/* Title */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Extraction Results</h1>
          <button className="cursor-pointer flex-row align-items-center orange" onClick={closeModalAndReset} type="button" style={{ borderRadius: 100 }}>
            <CloseIcon />
          </button>
        </div>

        {renderResults()}

      </div>
    </div>
  );
};

LoanDocumentInfoModal.defaultProps = {
  fileID: null,
};

LoanDocumentInfoModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  fileID: PropTypes.string,
  resetState: PropTypes.func.isRequired,
};

export default LoanDocumentInfoModal;
