/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const TaskList = ({ userInfo, selectTask, viewTaskDocument, previewTaskFile }) => {
  const loadTasks = () => (
    <div className="flex-column">
      {userInfo && userInfo.Tasks.map((task, index) => (
        <div key={userInfo.Name + task.Name + index}>
          <div className={`${index === 0 && 'border'} padding-20 flex-row align-items-center blue`} style={{ borderLeft: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
            <span className={`dot ${task.Completed ? 'background-green' : 'background-red'} margin-right-20`} />
            <button type="button" onClick={() => selectTask(task, index)} className={`background-none ${!task.Completed ? 'cursor-pointer' : ''}`}>
              <h3>
                {task.Name}
              </h3>
            </button>
            {task.Completed && task.BoxFileID && (
              <>
                <button type="button" className="background-none color-purple cursor-pointer" onClick={() => previewTaskFile(task.BoxFileID)}>
                  <h3>(File)</h3>
                </button>
                <button type="button" className="background-none color-purple cursor-pointer" onClick={() => viewTaskDocument(task.BoxFileID)}>
                  <h3>(Text)</h3>
                </button>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex margin-left-20 margin-right-20 padding-left-20 padding-right-20">
      <h2 className="margin-bottom-20">My Tasks</h2>
      {loadTasks()}
    </div>
  );
};

TaskList.defaultProps = {
  userInfo: null,
};

TaskList.propTypes = {
  userInfo: PropTypes.shape({
    Tasks: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string,
    })),
  }),
  selectTask: PropTypes.func.isRequired,
  viewTaskDocument: PropTypes.func.isRequired,
};

export default TaskList;
