import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';

import { applyCookie, getCookie } from '../../utils/cookies';
import { LOGIN_WITH_CREDENTIALS } from '../../utils/api';
import Footer from '../../components/Footer';
import logo from '../../assets/logo.png';

const LoginPage = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [requireNewPassword, setRequireNewPassword] = useState(false);
  const [passwordsNoMatch, setPasswordsNoMatch] = useState(false);

  const [username, setUsername] = useState('test@revasolutions.com');
  const [password, setPassword] = useState('password');
  const [newPassword, setNewPassword] = useState('');
  const [dupNewPassword, setDupNewPassword] = useState('');

  /**
   * Checks if the new passwords match.
   */
  const checkNewPassword = () => {
    if (newPassword !== dupNewPassword) {
      setPasswordsNoMatch(true);
      return true;
    }
    setPasswordsNoMatch(false);
    return false;
  };

  /**
   * Send credentials to login and retrieve access tokens.
   */
  const requestLogin = async (e) => {
    e.preventDefault();
    const payload = {
      username,
      password,
      newPassword,
    };
    if (requireNewPassword) {
      if (checkNewPassword()) {
        return;
      }
    }

    setAuthenticating(true);
    try {
      const {
        requireNewPassword: needNewPassword, boxToken, cognitoToken,
      } = await LOGIN_WITH_CREDENTIALS(payload);
      if (needNewPassword) {
        setAuthenticating(false);
        setRequireNewPassword(true);
      }
      if (boxToken && cognitoToken) {
        const timeExpiring = boxToken.accessTokenTTLMS + boxToken.acquiredAtMS;
        applyCookie('boxToken', JSON.stringify(boxToken), timeExpiring);
        applyCookie('cognitoToken', JSON.stringify(cognitoToken), (new Date().getTime() + 60 * 60 * 1000));
        setAuthenticated(true);
      }
    } catch (err) {
      setAuthenticating(false);
      alert(err);
    }
  };

  /**
   * Check if tokens already exist. If so, user is already authenticated and can log in.
   */
  const checkForTokens = () => {
    try {
      const boxToken = JSON.parse(getCookie('boxToken'));
      const cognitoToken = JSON.parse(getCookie('cognitoToken'));
      return boxToken && cognitoToken;
    } catch (err) {
      return false;
    }
  };

  /**
   * Checks if cookie exist. Redirects to main page if there are.
   */
  useEffect(() => {
    if (checkForTokens()) {
      setAuthenticated(true);
    }
  }, []);

  /**
   * Redirects to the main page if authenticated
   */
  if (authenticated) {
    return (
      <Redirect to="/main" />
    );
  }

  return (
    <div className="flex-column page align-items-center">
      {/* Header */}
      <div className="flex-row align-items-center padding-top-70">
        <img src={logo} alt="Reva Solutions Logo" width={400} />
      </div>

      {/* Login Form */}
      <div className="margin-top-70 text-align-center background-white padding-40 border-radius-10" style={{ width: 400 }}>
        {
          authenticating && (
            <>
              <CircularProgress size={60} className="margin-top-40" />
              <h3 className="margin-top-60">Authenticating...</h3>
            </>
          )
        }
        {
          !authenticating && !requireNewPassword && (
            <div className="border-grey padding-30 border-radius-5 background-purple">
              <h1 className="margin-bottom-30 color-white">Welcome back!</h1>
              <p className="margin-bottom-20 color-white">Login to access your loans and update your profile.</p>
              <form className="flex-column" onSubmit={requestLogin}>

                <input
                  className="padding-5 margin-left-20 margin-right-20 margin-bottom-10 p"
                  style={{ fontSize: 18 }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="email"
                  placeholder="E-mail"
                  autoComplete="on"
                />

                <input
                  className="padding-5 margin-left-20 margin-right-20 margin-bottom-20 p"
                  style={{ fontSize: 18 }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Password"
                  autoComplete="on"
                />

                <input
                  className="p padding-top-10 padding-bottom-10 margin-left-20 margin-right-20 background-orange color-white border-none cursor-pointer border-radius-5"
                  style={{ fontSize: 20 }}
                  type="submit"
                  value="Log in"
                />
              </form>
            </div>
          )
        }
        {
          !authenticating && requireNewPassword && (
            <div className="border-grey padding-30 border-radius-5 background-purple">
              <h1 className="margin-bottom-30 color-white">Change Password</h1>
              <p className="margin-bottom-20 color-white">Please set a new password to fully secure your account.</p>
              <form className="flex-column" onSubmit={requestLogin}>
                <input
                  className="padding-5 margin-left-20 margin-right-20 margin-bottom-20 p"
                  style={{ fontSize: 18 }}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  placeholder="New Password"

                />
                <input
                  className="padding-5 margin-left-20 margin-right-20 margin-bottom-20 p"
                  style={{ fontSize: 18 }}
                  value={dupNewPassword}
                  onChange={(e) => setDupNewPassword(e.target.value)}
                  type="password"
                  placeholder="Repeat New Password"
                  onBlur={() => checkNewPassword()}
                />
                {
                  passwordsNoMatch && (
                    <p className="margin-bottom-20 color-red">Passwords do not match.</p>
                  )
                }
                <input
                  className="color-white p padding-top-10 padding-bottom-10 margin-left-20 margin-right-20 background-orange border-none cursor-pointer orange border-radius-5"
                  style={{ fontSize: 20 }}
                  type="submit"
                  value="Set Password"
                />

              </form>
            </div>
          )
        }
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
