import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';

import { REQUEST_LOGOUT } from '../../utils/api';
import { removeCookie } from '../../utils/cookies';

const LogoutPage = () => {
  const [logoutComplete, setLogoutComplete] = useState(false);

  useEffect(() => {
    const logout = async () => {
      try {
        await REQUEST_LOGOUT();
      } catch (err) {
        console.log(err);
      } finally {
        removeCookie('cognitoToken');
        removeCookie('boxToken');
        setLogoutComplete(true);
      }
    };
    logout();
  }, []);

  if (logoutComplete) {
    return <Redirect to="/" />;
  }
  return (
    <div className="page flex-column">
      <div className="margin-auto text-align-center">
        <CircularProgress size={100} />
        <p className="margin-top-20">Logging out...</p>
      </div>
    </div>
  );
};

export default LogoutPage;
