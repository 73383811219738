import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useRouteMatch,
  NavLink,
} from 'react-router-dom';
import FaceIcon from '@material-ui/icons/Face';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import logo from '../../assets/logo-whiteorange.png';

const Header = ({ userInfo, logout }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const match = useRouteMatch();

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  useEffect(() => {
    const listener = (e) => {
      if (!e.target.matches('.dropdown-button')) {
        setShowDropDown(false);
      }
    };
    window.addEventListener('mousedown', listener);
    return function cleanup() {
      window.removeEventListener('mousedown', listener);
    };
  }, []);

  return (
    <header className="flex-row padding-left-30 justify-content-space-between background-purple">
      <div className="flex-row align-items-center margin-auto justify-content-space-between content-max-width" style={{ height: 'inherit' }}>
        <img src={logo} className="margin-top-10 margin-top-bottom margin-right-40 margin-left-40" style={{ height: 'calc(100% - 40px)' }} alt="Reva Solutions Logo" />
        <div className="flex-row align-items-center margin-right-40">
          {/* Links */}
          <div className="flex-row align-items-center">
            <h4 className="padding-left-10 padding-right-10">
              <NavLink exact to={match.url} className="margin-right-10 blue-hover color-white padding-left-10 padding-right-10 " activeClassName="padding-bottom-10 color-blue border-bottom-blue">DASHBOARD</NavLink>
            </h4>
            <h4>
              <NavLink to={`${match.url}/loans`} className="margin-right-10 blue-hover color-white padding-left-10 padding-right-10 " activeClassName="padding-bottom-10 color-blue border-bottom-blue">LOANS</NavLink>
            </h4>
            <h4>
              <NavLink to={`${match.url}/profile`} className="margin-right-10 blue-hover color-white padding-left-10 padding-right-10 " activeClassName="padding-bottom-10 color-blue border-bottom-blue">PROFILE</NavLink>
            </h4>
          </div>
          {/* User Dropdown */}
          <div style={{ position: 'relative', display: 'inline-block' }}>
            {userInfo && (
              <button
                onClick={toggleDropDown}
                className={`padding-10 color-white background-none cursor-pointer blue text-decoration-none border-radius-5 flex-row ${showDropDown && 'background-white'}`}
                style={{ fontSize: 20 }}
                type="button"
              >
                <FaceIcon className="margin-right-10" />
                {`${userInfo.Name}`}
                <ArrowDropDownIcon />
              </button>
            )}

            <div
              className="border-radius-5 margin-top-5 text-align-right"
              style={{ display: `${showDropDown ? 'block' : 'none'}`, position: 'absolute', zIndex: 100 }}
            >
              <NavLink 
                exact 
                to={`${match.url}/editProfile`} 
              >
                <button
                  style={{ minWidth: 160 }}
                  className="padding-10 border cursor-pointer dropdown-button"
                  type="button"
                >
                  Edit Profile
                </button>
              </NavLink>
              <button
                style={{ minWidth: 160 }}
                className="padding-10 border-grey cursor-pointer dropdown-button"
                onClick={logout}
                type="button"
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  userInfo: null,
};

Header.propTypes = {
  userInfo: PropTypes.shape({
    Name: PropTypes.string,
  }),
  logout: PropTypes.func.isRequired,
};

export default Header;
