import axios from 'axios';
import jwt from 'jsonwebtoken';

import { getCookie } from './cookies';

const API_ENDPOINT = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_ENDPOINT : process.env.REACT_APP_DEV_ENDPOINT;

const LOGIN_URL = `${API_ENDPOINT}/client/login`;
const AUTHENTICATE_CODE_URL = `${API_ENDPOINT}/client/redirect`;
const VALIDATE_TOKEN_URL = `${API_ENDPOINT}/client/token/validate`;
const LOGOUT_URL = `${API_ENDPOINT}/client/logout`;
const GET_USER_INFO_URL = `${API_ENDPOINT}/client/user`;
const UPLOAD_FILES_URL = `${API_ENDPOINT}/client/file/upload`;
const LOAN_CONFIG_URL = `${API_ENDPOINT}/loans`;
const DOCUMENT_AI_URL = `${API_ENDPOINT}/loans/result`;
const UPLOAD_FILE_TO_LOAN_URL = `${API_ENDPOINT}/client/loan/upload`;

/**
 * Extracts access token from cookie.
 */
const extractBoxToken = () => {
  try {
    const token = JSON.parse(getCookie('boxToken')).accessToken;
    return token;
  } catch (err) {
    return '';
  }
};

/**
 * Extract cognito access token.
 */
const extractCognitoAccessToken = () => {
  try {
    const token = JSON.parse(getCookie('cognitoToken')).accessToken;
    return token;
  } catch (err) {
    return '';
  }
};

/**
 * Extracts cognito id from cognito Token's id token
 */
const extractCognitoID = () => {
  try {
    const token = JSON.parse(getCookie('cognitoToken')).accessToken;
    const decoded = jwt.decode(token).sub;
    return decoded;
  } catch (err) {
    return '';
  }
};

/**
 * Retrieves Cognito Login URL
 */
export const GET_LOGIN_URI = async () => {
  try {
    const response = await axios.get(LOGIN_URL);
    return response.data;
  } catch (err) {
    console.log(err);
    return '';
  }
};

/**
 * Login with user credentials to retrieve authentication tokens.
 */
export const LOGIN_WITH_CREDENTIALS = async (data) => {
  try {
    const response = await axios.post(LOGIN_URL, data);
    return response.data;
  } catch (err) {
    throw Error(err.response.data);
  }
};

/**
 * Exchanges code for Cognito JWT and Box access token.
 * @param {string} code code query string from url
 */
export const AUTHENTICATE_CODE = async (code) => {
  try {
    const response = await axios.get(AUTHENTICATE_CODE_URL, {
      params: {
        code,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/**
 * Validates the token in browser cookie to access page
 */
export const VALIDATE_TOKEN = async () => {
  const token = extractBoxToken();

  try {
    const response = await axios.get(VALIDATE_TOKEN_URL, {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
  return false;
};

/**
 * Retrieves the Logout URI and invalidates Cognito Token.
 */
export const REQUEST_LOGOUT = async () => {
  const boxToken = extractBoxToken();
  const cognitoToken = extractCognitoAccessToken();
  const data = {
    cognitoToken,
    boxToken,
  };
  try {
    const response = await axios.post(LOGOUT_URL, data);
    return response.data;
  } catch (err) {
    console.log(err);
    return '';
  }
};

/**
 * Retrieves user information.
 */
export const GET_USER_INFO = async () => {
  const token = extractBoxToken();
  const cognitoID = extractCognitoID();

  try {
    const response = await axios.get(`${GET_USER_INFO_URL}/${cognitoID}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Update user profile.
 */
 export const UPDATE_USER_PROFILE = async (data) => {
  const token = extractBoxToken();
  const cognitoID = extractCognitoID();

  try {
    const response = await axios.patch(`${GET_USER_INFO_URL}/${cognitoID}`, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

export const UPLOAD_FILES = async (data) => {
  const token = extractBoxToken();
  const cognitoID = extractCognitoID();
  data.append('cognitoID', cognitoID);
  try {
    const response = await axios.post(UPLOAD_FILES_URL, data, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Get loan configuration setup
 */
export const GET_LOAN_CONFIG = async () => {
  try {
    const response = await axios.get(LOAN_CONFIG_URL);
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Upload a file to complete a task in a Loan
 * @param {*} data FormData
 */
export const UPLOAD_FILE_TO_LOAN = async (data) => {
  const token = extractBoxToken();

  try {
    const response = await axios.post(UPLOAD_FILE_TO_LOAN_URL, data, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

/**
 * Get Document AI Result from Backen by passing in file id
 * @param {string} fileID box file id
 */
export const GET_DOCUMENT_AI_RESULT = async (fileID) => {
  const token = extractBoxToken();

  try {
    const response = await axios.get(`${DOCUMENT_AI_URL}/${fileID}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};
