import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { UPDATE_USER_PROFILE } from "../../../utils/api";

const USER_NAME_ID = "userNameId";
const USER_EMAIL_ID = "userEmailId";
const USER_PHONE_ID = "userPhoneId";
const USER_ADDRESS_ID = "userAddressId";
const USER_CITY_ID = "userCityId";
const USER_STATE_ID = "userStateId";
const USER_ZIP_ID = "userZipId";

const normalizePhoneNumber = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

const normalizeZip = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 5) {
      return currentValue;
    } else {
      return currentValue.slice(0, 5);
    }
  }
};

const EditProfilePage = ({ userInfo, getUserInfo }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [updating, setUpdating] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.Name);
      setEmail(userInfo.Email);
      setPhone(normalizePhoneNumber(userInfo.Phone));
      setAddress(userInfo.Address.Address);
      setCity(userInfo.Address.City);
      setState(userInfo.Address.State);
      setZip(userInfo.Address.Zip);
    }
  }, []);

  useEffect(() => {
    if (completed) {
      const timeId = setTimeout(() => {
        setCompleted(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [completed]);

  const updateProfile = async () => {
    setUpdating(true);
    const payload = {
      phone: phone.replace(/[^\d]/g, ""),
      address: {
        Address: address,
        City: city,
        State: state,
        Zip: zip,
      },
    };
    try {
      await UPDATE_USER_PROFILE(payload);
      setUpdating(false);
      setCompleted(true);
    } catch (err) {
      alert(err);
      setUpdating(false);
    }
  };

  return (
    <>
      {completed && (
        <div className="background-green text-align-center">
          <h3 className="margin-top-10 margin-bottom-10">Update Successful.</h3>
        </div>
      )}
      <div className="flex-row margin-left-auto margin-right-auto margin-top-60 text-align-center content-max-width">
        <div className="flex margin-left-20 margin-right-20 padding-left-20 padding-right-20 text-align-left">
          <form className="flex-row">
            <div className="flex-column margin-right-30">
              <label
                className="margin-bottom-20 flex-column"
                htmlFor={USER_NAME_ID}
              >
                <h2>Name:</h2>
                <input
                  disabled="disabled"
                  className="margin-top-5 margin-bottom-10"
                  style={{ width: "150px" }}
                  value={name}
                  id={USER_NAME_ID}
                />
              </label>

              <label
                className="margin-bottom-20 flex-column"
                htmlFor={USER_EMAIL_ID}
              >
                <h2>Email:</h2>
                <input
                  disabled="disabled"
                  className="margin-top-5 margin-bottom-10"
                  style={{ width: "250px" }}
                  value={email}
                  id={USER_EMAIL_ID}
                />
              </label>

              <label
                className="margin-bottom-20 flex-column"
                htmlFor={USER_PHONE_ID}
              >
                <h2>Phone number:</h2>
                <input
                  className="margin-top-5 margin-bottom-10"
                  style={{ width: "110px" }}
                  placeholder="(555)555-5555"
                  value={phone}
                  onChange={(e) => setPhone(normalizePhoneNumber(e.target.value))}
                  id={USER_PHONE_ID}
                />
              </label>

              <label className="flex-column" htmlFor={USER_ADDRESS_ID}>
                <h2>Address:</h2>
                <input
                  className="margin-top-5 margin-bottom-10"
                  style={{ width: "350px" }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  id={USER_ADDRESS_ID}
                />
              </label>

              <div className="flex-row">
                <label
                  className="margin-bottom-20 flex-column"
                  htmlFor={USER_CITY_ID}
                >
                  <h2>City:</h2>
                  <input
                    className="margin-top-5 margin-bottom-10 margin-right-20"
                    style={{ width: "160px" }}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    id={USER_CITY_ID}
                  />
                </label>

                <label
                  className="margin-bottom-20 flex-column margin-right-20"
                  htmlFor={USER_STATE_ID}
                >
                  <h2>State:</h2>
                  <input
                    className="margin-top-5 margin-bottom-10"
                    style={{ width: "90px" }}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    id={USER_STATE_ID}
                  />
                </label>

                <label
                  className="margin-bottom-20 flex-column"
                  htmlFor={USER_ZIP_ID}
                >
                  <h2>Zip:</h2>
                  <input
                    className="margin-top-5 margin-bottom-10"
                    style={{ width: "45px" }}
                    value={zip}
                    onChange={(e) => setZip(normalizeZip(e.target.value))}
                    id={USER_ZIP_ID}
                  />
                </label>
              </div>
              <button
                disabled={updating}
                className={`cursor-pointer color-white ${updating || completed ? "background-lightgrey" : "background-blue"}
                  margin-top-20 padding-left-30 padding-right-30 padding-top-10 padding-bottom-10`}
                onClick={(e) => {
                  e.preventDefault();
                  updateProfile();
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {updating && (
        <div className="text-align-center center">
          <CircularProgress size={120} />
        </div>
      )}
    </>
  );
};

export default EditProfilePage;
